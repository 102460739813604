import React from "react";

import { ConfirmOrder } from "../../components/Cart";

const ConfirmOrderPage = () => {
  return (
    <>
      <ConfirmOrder />
    </>
  );
};

export default ConfirmOrderPage;
