import React from "react";

import { EnrolledCourses } from "../../components/Course";

const EnrolledCoursesPage = () => {
  return (
    <>
      <EnrolledCourses />
    </>
  );
};

export default EnrolledCoursesPage;
