import React from "react";

import { Cart } from "../../components/Cart";

const CartPage = () => {
  return (
    <>
      <Cart />
    </>
  );
};

export default CartPage;
