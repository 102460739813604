import React from "react";

import { Courses } from "../../components/Course";

const CoursesPage = () => {
  return (
    <>
      <Courses />
    </>
  );
};

export default CoursesPage;
